import '../styles/globals.scss'
import "/styles/home.scss"
import "/styles/nav.scss"
import "/styles/footer.scss"
import "/styles/homePageCard.scss"
import "/styles/partnersCarousel.scss"
import "/styles/circle.scss"
import "/styles/signIn.scss"
import "/styles/admin.scss"
import "/styles/projects.scss"
import "/styles/scrollToTop.scss"
import "/styles/news.scss"
import "/styles/team.scss"
import AdminLayout from "../components/layouts/AdminLayout";
import UserLayout from "../components/layouts/UserLayout";
import NotFound from "./404";
import NoLayout from "../components/layouts/NoLayout";
import {UserContextProvider} from "../components/contexts/UserContext";
import 'bootstrap/dist/css/bootstrap.min.css';
import InternalServerError from "./500";
import {useRouter} from "next/router";
import {useEffect} from "react";
import 'react-loading-skeleton/dist/skeleton.css'

const layouts = {
    AdminLayout: AdminLayout,
    UserLayout: UserLayout,
    NotFound: NotFound,
    NoLayout: NoLayout,
    InternalServerError: InternalServerError
};

export default function MyApp({Component, pageProps}) {
    const Layout = layouts[Component.layout] || ((children) => <>{children}</>);
    const router = useRouter();

    // useEffect(() => {
    //     console.log("ddssd")
    //     // alert(router.asPath)
    //
    //     if (typeof window !== 'undefined' && window.localStorage.getItem("language")) {
    //         router.push(router.asPath, {}, {locale: window.localStorage.getItem("language")})
    //     }
    //
    // }, [])

    useEffect(()=>{
        if (typeof window !== 'undefined'){
            window.localStorage.setItem('language', router.locale)
        }

    },[router.locale])

    return (
        <UserContextProvider>
            <Layout>
               {/* <NextNProgress
                    color="#29D"
                    startPosition={0.3}
                    stopDelayMs={200}
                    height={3}
                    showOnShallow={true}
                />*/}
                <Component {...pageProps} />
            </Layout>
        </UserContextProvider>
    )
}

